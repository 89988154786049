import React from 'react';
import './settings.css'; // Import CSS for styling
import XButton from "./x_button";
import Snackbar from './custom_snackbar';

const SettingsScreen = () => {

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [feedbackType, setFeedbackType] = React.useState('positive');
  const [sbMessage, setSBMessage] = React.useState("");

  const triggerSnackbar = (type, message) => {
    setFeedbackType(type);
    setSBMessage(message);
    setShowSnackbar(true);
  };

  const handleResetPassword  = async () => {
  
    const email = localStorage.getItem('email');
  
    // await delay(3000);
    try {
      const response = await fetch('https://api.yourtalentid.com/api/auth/reset_password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Redirect or update UI as needed
        console.log('Password successfully reset:', data.message);
        triggerSnackbar("positive", `We've sent password reset instructions to ${email}`)
      } else {
        console.error('Password reset failed:', data.message);
        triggerSnackbar("negative", "Oop! Something went wrong")
        // setErrors({
        //   ...errors,
        //   generic_error : `${data.message}`
        // })
      }
  
    } catch (error) {
      console.error('Error:', error);
      triggerSnackbar("negative", "Oop! Something went wrong")
    }
  };


  return (
    <div className="settings-container">
      {showSnackbar && (
        <Snackbar
          message={sbMessage}
          type={feedbackType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
      <XButton/>

      <h2 className="settings-header">Settings</h2>

      {/* Account Settings Section */}
      <div className="settings-section">
        <h3>Account Settings</h3>
        <div className="settings-item" onClick={handleResetPassword}>
          <span>Change Password</span>
          <i className="fa-solid fa-chevron-right"></i>
        </div>
        <div className="settings-item">
          <span>Email Notifications</span>
          <input type="checkbox" />
        </div>
        {/* <div className="settings-item">
          <span>Manage Email</span>
          <i className="fa-solid fa-chevron-right"></i>
        </div> */}
      </div>

      {/* Notification Settings Section */}
      {/* <div className="settings-section">
        <h3>Notifications</h3>
        <div className="settings-item">
          <span>Push Notifications</span>
          <input type="checkbox" />
        </div>
        <div className="settings-item">
          <span>Email Notifications</span>
          <input type="checkbox" />
        </div>
      </div> */}

      {/* App Preferences Section */}
      {/* <div className="settings-section">
        <h3>App Preferences</h3>
        <div className="settings-item">
          <span>Language</span>
          <i className="fa-solid fa-chevron-right"></i>
        </div>
        <div className="settings-item">
          <span>Dark Mode</span>
          <input type="checkbox" />
        </div>
      </div> */}
    </div>
  );
};

export default SettingsScreen;
