import React, { useEffect } from "react";
import {useNavigate} from "react-router-dom";

const XButton = () => {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(-1);
    }


    return (
        <div onClick={onClick} style={{margin: "10px"}}>
        <i class="fa-solid fa-xmark"></i>
      </div>
    );
}

export default XButton;