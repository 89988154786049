import React from "react";
import {useNavigate} from "react-router-dom";
import Loader from "./loader";
import Logo from "./logo"
import XButton from "./x_button";
import Snackbar from "./custom_snackbar";

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function FeedbackPage() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    message: ""
  });


  const [errors, setErrors] = React.useState({
    invalid_credentials: "",
    email_already_in_use: "",
    generic_error: ""
  });

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [feedbackType, setFeedbackType] = React.useState('positive');
  const [sbMessage, setSBMessage] = React.useState("");

  const triggerSnackbar = (type, message) => {
    setFeedbackType(type);
    setSBMessage(message);
    setShowSnackbar(true);
  };


  const [isLoading, setLoading] = React.useState(false);


  const startLoading = () => {
      setLoading(true);
      console.log(`Starting to load: ${isLoading}`)
  }


  const stopLoading = () => {
    setLoading(false);
    console.log(`stopping to load: ${isLoading}`)
  }


  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };



  const sendFeedback = async (e) => {
    e.preventDefault();
    
    /// Reset errors
    setErrors({
      invalid_credentials: "",
      email_already_in_use: "",
      generic_error: ""
    });

    const email = state.email;
    const message = state.message;
    
    startLoading();
    // await delay(3000);
    try {
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email')
      const response = await fetch('https://api.yourtalentid.com/api/feedback/send-feedback', {
      // const response = await fetch('http://localhost:5000/api/feedback/send-feedback', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, message}),
      });

      const data = await response.json();

      if (response.ok) {
        // Redirect or update UI as needed
        console.log('feedback sent successfully:', data.token);
        triggerSnackbar("positive", "We've received your feedback. Thank you! ");
      } else {
        console.error('send feedback failed:', data.message);
        triggerSnackbar("negative", "Oops! An error occured");
        // setErrors({
        //   ...errors,
        //   generic_error : `${data.message}`
        // })

      }
    } catch (error) {
      console.error('Error:', error);
    }
    stopLoading();
  };

  return (
    <div className="form-container ">
      {showSnackbar && (
        <Snackbar
          message={sbMessage}
          type={feedbackType}
          onClose={() => setShowSnackbar(false)}
        />
      )}

      <div style={{margin: "20px 20px"}}>
          <XButton/>
          <form onSubmit={sendFeedback}>
          <div className="settings-header">Please provide suggestions to help improve our platform.</div>

          {/* <span style={{margin: "15px auto"}}>Start building your professional profile today. Sign up below.</span> */}
          
          {/* <input
          type="text"
          name="first_name"
          value={state.first_name}
          onChange={handleChange}
          placeholder="First Name"
          />

          <input
          type="text"
          name="last_name"
          value={state.last_name}
          onChange={handleChange}
          placeholder="Last Name"
          />

          <input
          type="email"
          name="email"
          value={state.email}
          onChange={handleChange}
          placeholder="Email"
          /> */}

          <input
          type="message"
          name="message"
          value={state.password}
          onChange={handleChange}
          placeholder="Message"
          />

          
          {errors.generic_error != "" ? <div className="form-error"> {errors.generic_error} </div> : <div/>}
          

          <button style={{margin: "15px auto"}} onClick={sendFeedback}>
          <div style={{display: "flex"}}>
              <div>
              Send Feedback
              </div>
              <Loader isLoading={isLoading}/>
          </div>
          </button>

          </form>
      </div>
    </div>
  );
}

export default FeedbackPage;
