import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import styled from 'styled-components';

const UrgeWithPleasureComponent = ({remain}) => (
  <CountdownCircleTimer
    true
    duration={3}
    initialRemainingTime={remain}
    colors="#A30000"
  />
)

const video = styled.video`
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
    display: block;
  }
`;

const VideoRecorder = forwardRef((props, ref) => {
  const {videoFile, setVideoTranscript, setVideoFile, foregroundColor, readOnly} = props;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [recording, setRecording] = useState(false);
  const [showFeed, setShowFeed] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const mediaRecorderRef = useRef(null);
  const videoRef = useRef(null);
  const videoPlayerRef = useRef(null);
  const streamRef = useRef(null);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [count, setCount] = useState(0);
  const [thumbnail, setThumbnail] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [isMuted, setIsMuted] = useState(false);


  async function countDown() {
    for (let i = 3; i > 0; i--) {
      setCount(i); // Execute the action
      if (i > 0) {
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for interval
      }
    }
  }

  const captureThumbnail = (videoElement) => {
    console.log("Capturing thumbnail")
    const canvas = document.createElement('canvas');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
  
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  
    try {
      const dataURL = canvas.toDataURL('image/png');
      console.log('Thumbnail captured:', dataURL);
    } catch (error) {
      console.error('Canvas export error:', error);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      extSetVideoUrl : (url) => {
        setVideoURL(url);
        console.log(`Video player ref`, videoPlayerRef)
        if(videoPlayerRef.current) {
          videoPlayerRef.current.addEventListener("loadeddata", ()=>{
            if (videoPlayerRef.current) {
              console.log("***Video Loaded***")
              videoPlayerRef.current.pause()
              // setIsMuted(true);
              // videoPlayerRef.current.play(); // Starts the video
              // setTimeout(() => {
              //   videoPlayerRef.current.pause()
              //   setIsMuted(false)
              // }, 1000);
            }
          });
        }
      }
    })
  );

  const startRecording = async () => {

    setIsCountingDown(true);
    await countDown()
    setIsCountingDown(false);

    setShowFeed(true);

    const mediaConstraints = {
      video: {
        width: { ideal: 1920 },   // Ideal resolution: 1920x1080 (Full HD)
        height: { ideal: 1080 },
        frameRate: { ideal: 30, max: 60 }, // Frame rate: 30 FPS, allow up to 60 FPS
        facingMode: "user"  // "user" for front camera, "environment" for rear camera
      },
      audio: {
        sampleRate: 44100,  // Higher sample rate for better audio quality
        // channelCount: 1, 
      }
    };
    

    try {
      // const stream = await navigator.mediaDevices.getUserMedia({ video: true , audio: true});
      const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      streamRef.current = stream;
      videoRef.current.srcObject = stream;
      videoRef.current.play();

      const options = {
        mimeType: 'video/webm;codecs=vp9', // High-quality video codec (VP9)
        bitsPerSecond: 5000000 // 5 Mbps bitrate for high video quality
      };

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks = [];
      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const videoFile = new File([blob], `recorded_video_${Date.now()}_${Math.floor(Math.random() * 10000)}.webm`
        , { type: 'video/webm' });
        setVideoFile(videoFile); //Set video file in the parent component.
        setVideoURL(url);
      };

      mediaRecorder.start();
      setRecording(true);
      
      // Stop the recording automatically after 2 minutes (120000ms)
      setTimeout(() => stopRecording(), 120000);

    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  const stopRecording = () => {
    setShowFeed(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    setRecording(false);
  };


  const isIOS = () => {
    // /iPad|iPhone|iPod/
    // /Windows/
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) && 
      !window.MSStream
    );
  };

  ///First time run
  useEffect(() => {
    console.log("first time run")
    const handleResize = () => setIsDesktop(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{display: "relative"}}>
      <div style={{display: isCountingDown == true ? "flex" : "none", width: "100%" , justifyContent: "center", margin: "100px 0"}} >
          <div style={{fontSize: "50px", color: foregroundColor}}>
            {count}
          </div>
      </div>

      <div style={{display: isCountingDown == true ? "none" : "flex", justifyContent: "center"}}>
        <video ref={videoRef} style={{display: showFeed ? "block" : "none", transform: "scaleX(-1)",}} width={isDesktop ? '100%' : '100%'} max-width="600px" autoPlay muted playsInline />
        <div>
      {/* {thumbnail && (
        <img
          src={thumbnail}
          alt="Video Thumbnail"
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => setShowFeed(true)}
        />
      )} */}
          {videoURL && (<video ref={videoPlayerRef} src={videoURL} style={{ display: showFeed ? "none" : "block", transform: "scaleX(1)" }} controls={true} width={isDesktop ? '100%' : '100%'} max-width="600px"  playsInline muted={isMuted ? true : false} autoPlay/>)}
    </div>
        
        {/* "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4" */}
        {/* {videoURL && (<video src={videoURL} style={{display: showFeed ? "none" : "block"}} controls width={isDesktop ? '50%' : '100%'} max-width="600px" />)} */}
      </div>

      {/* {showFeed ? <video ref={videoRef}  width="320" height="240" autoPlay muted /> : <video src={videoURL} controls width="320" height="240" />} */}
      {/* <video ref={videoRef}  width="320" height="240" autoPlay muted /> */}
      <div style={{width: "100%", display: 'flex', padding: "auto 20px"}}>

        {/* Record button - refresh */}
         <div style={{display: readOnly || isIOS() ? "none" : "block", margin:"10px auto"}}>
          {!recording ? (
                <button onClick={startRecording} style={{backgroundColor: foregroundColor}} >Start Recording</button>
            ) : (
                 <button onClick={stopRecording} style={{backgroundColor: foregroundColor}}>Stop Recording</button>
            )}
        </div>

      </div>
      <div style={{height: "10px"}}></div>
      {/* {videoURL && (
        <div>
          <video src={videoURL} style={{display: showFeed ? "none" : "block"}} controls width="320" height="240" />
          <a href={videoURL} download="recording.webm">{showFeed ? "showFeed" : "Don't show feed"}</a>
        </div>
      )} */}
    </div>
  );
})

export default VideoRecorder;
