import React from "react";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom"
import Loader from "./loader";
import Logo from "./logo"

// Something's changed
function SignInForm() {

  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({
    invalid_credentials: "",
    email_already_in_use: "",
    generic_error: ""
  });

  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const gotoProfilePage = () => {
    navigate('/pofile')
  }

  const gotoForgotPasswordScreen = () => {
    navigate('/reset-password')
  }

  const gotoSignUpPage = () => {
    const data = { page: "signUp" };
    
    navigate('/auth', { state: data });
    window.location.reload();
  };


  const [state, setState] = React.useState({
    email: "",
    password: ""
  });

  const [isLoading, setLoading] = React.useState(false);
  const startLoading = () => {
      setLoading(true);
      console.log(`Starting to load: ${isLoading}`)
  }

  const stopLoading = () => {
    setLoading(false);
    console.log(`stopping to load: ${isLoading}`)
  }


  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };


  const handleOnSubmit = evt => {
    evt.preventDefault();

    const { email, password } = state;
    alert(`You are login with email: ${email} and password: ${password}`);

    gotoProfilePage()

    for (const key in state) {
      setState({
        ...state,
        [key]: ""
      });
    }
  };

  
  const handleLogin = async (e) => {

    setErrors({
      invalid_credentials: "",
      email_already_in_use: "",
      generic_error: ""
    });

    e.preventDefault();
    const email = state.email;
    const password = state.password;
    
    startLoading();
    // await delay(3000);
    try {
      const response = await fetch('https://api.yourtalentid.com/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {

        console.log(`JWT token from backend: ${data.token}`)
        // Store the JWT token in localStorage or sessionStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('token_created', Date.now())
        localStorage.setItem('email', email)

        // Redirect or update UI as needed
        console.log('Logged in successfully:', data.token);

        localStorage.setItem("just_signed_up", true);
        navigate("/profile");
      } else {
        console.error('Login failed:', data.message);
        setErrors({
          ...errors,
          generic_error : `${data.message}`
        })
      }

    } catch (error) {
      console.error('Error:', error);
    }
    stopLoading();
  };

  return (
    <div className="form-container sign-in-container" >
      <form onSubmit={handleOnSubmit}>
      <Logo/>
        <h1>Sign in</h1>
        {/* <div className="social-container">
          <a href="#" className="social">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="#" className="social">
            <i className="fab fa-google-plus-g" />
          </a>
          <a href="#" className="social">
            <i className="fab fa-linkedin-in" />
          </a>
        </div> */}
        <span style={{margin: "15px auto"}}>Access your profile and unlock your next career opportunity</span>
        {/* <span>or use your account</span> */}
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={state.email}
          onChange={handleChange}
        />
        <div style={{width: '100%', display: "flex", alignItems: "center"}}>
          <input
            type={passwordVisible ? 'text' : 'password'}
            // type="password"
            name="password"
            placeholder="Password"
            value={state.password}
            onChange={handleChange}
          />
          <div onClick={togglePasswordVisibility} style={{padding: "10px", backgroundColor: "#EEEEEE"}}>
          {passwordVisible ? <i class="fa-solid fa-eye-slash"></i> : <i class="fa-solid fa-eye"></i> }
          </div>
        </div>



        {errors.generic_error != "" ? <div className="form-error"> {errors.generic_error} </div> : <div/>}

        <div style={{margin: "15px auto"}} onClick={gotoForgotPasswordScreen}>Forgot your password?</div>
        <Link style={{margin: "15px auto"}} to='/profile'>
          <button onClick={handleLogin} style={{display: "flex"}}>
            <div>
              Sign In
            </div>
            <Loader isLoading={isLoading}/>
            </button>
        </Link>
        <div style={{padding: "15px auto", color: "blue"}} className="hover-underline"
            // id="signUp"
            onClick={gotoSignUpPage}>
            Sign Up
          </div>
        
      </form>
    </div>
  );
}


export default SignInForm;
