import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Pricing</h1>
            {/* <p className="font13">
              Take a look at our comprehensive pricing plan.
              <br />
            </p> */}
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon=""
                price="$99.99"
                title="Primary Plan"
                text="To create your perfect resume!"
                offers={[
                  { name: "Edit All 3 Videos", cheked: true },
                  { name: "Edit Profile Data", cheked: true },
                  { name: "Change Profile Picture", cheked: true },
                  { name: "Edit portfolio", cheked: true },
                  { name: "Edit portfolio", cheked: true },
                ]}
                action={() => {}}
              />
            </TableBox>
            {/* <TableBox>
              <PricingTable
                icon=""
                price="$24,99/yr"
                title="Primary Plan - Cont"
                text="Renewal of the of Primary Plan, a year after and onwards."
                offers={[
                  { name: "Edit All 3 Videos", cheked: true },
                  { name: "Edit Profile Data", cheked: true },
                  { name: "Change Profile Picture", cheked: true },
                  { name: "Edit portfolio", cheked: true },
                  { name: "Edit portfolio", cheked: true },
                ]}
                action={() => {}}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                  price="$9,99"
                  title="Edit fee"
                  text="To create your perfect resume!"
                  offers={[
                    { name: "Edit All 3 Videos", cheked: true },
                  ]}
                  action={() => {}}
              />
            </TableBox> */}
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




