import React, { useState, useEffect } from 'react';
import Loader from './loader';
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom"
import Logo from "./logo"
import XButton from './x_button';
import Snackbar from './custom_snackbar';
// refresh


const ResetPassword = () => {
const navigate = useNavigate();

const [errors, setErrors] = React.useState({
  invalid_credentials: "",
  email_already_in_use: "",
  generic_error: ""
});

const [result, setResult] = React.useState({});


const gotoProfilePage = () => {
  navigate('/pofile')
}


const [state, setState] = React.useState({
  email: "",
});


const [isLoading, setLoading] = React.useState(false);

const [showSnackbar, setShowSnackbar] = React.useState(false);
const [feedbackType, setFeedbackType] = React.useState('positive');
const [sbMessage, setSBMessage] = React.useState("");

const triggerSnackbar = (type, message) => {
  setFeedbackType(type);
  setSBMessage(message);
  setShowSnackbar(true);
};

const startLoading = () => {
    setLoading(true);
    console.log(`Starting to load: ${isLoading}`)
}

const stopLoading = () => {
  setLoading(false);
  console.log(`stopping to load: ${isLoading}`)
}


const handleOnChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
};

const gotoPreviousPage = () => {
  navigate(-1);
}


const handleOnSubmit = evt => {
  evt.preventDefault();

  const { email, password } = state;
  alert(`You are login with email: ${email} and password: ${password}`);

  gotoProfilePage()

  for (const key in state) {
    setState({
      ...state,
      [key]: ""
    });
  }
};


const handleResetPassword  = async (e) => {

  setErrors({
    invalid_credentials: "",
    email_already_in_use: "",
    generic_error: ""
  });

  e.preventDefault();
  const email = state.email;

  startLoading();
  // await delay(3000);
  try {
    const response = await fetch('https://api.yourtalentid.com/api/auth/reset_password', {
      // const response = await fetch('http://localhost:5000/api/auth/reset_password', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (response.ok) {
      // Redirect or update UI as needed
      console.log('Password successfully reset:', data.message);
      triggerSnackbar("positive", `We've sent password reset instructions to ${email}`)
    } else {
      console.error('Password reset failed:', data.message);
      triggerSnackbar("negative", "Oop! Something went wrong")
      // setErrors({
      //   ...errors,
      //   generic_error : `${data.message}`
      // })
    }

  } catch (error) {
    console.error('Error:', error);
    triggerSnackbar("negative", "Oop! Something went wrong")
  }
  stopLoading();
};



return (
  <div className="form-container sign-in-container" style={{margin: "20px auto"}} >
    {showSnackbar && (
      <Snackbar
        message={sbMessage}
        type={feedbackType}
        onClose={() => setShowSnackbar(false)}
      />
    )}

    <div style={{padding: "0px 20px"}}>
    {/* <div onClick={gotoPreviousPage}>
      <i class="fa-solid fa-xmark"></i>
    </div> */}

    <XButton/>
    <form onSubmit={handleOnSubmit}>
    <Logo/>
      <h1>Reset Password</h1>
      <span style={{margin: "15px auto"}}>Input your email in order to reset your password</span>
      {/* <span>or use your account</span> */}
      <input
        type="email"
        placeholder="Email"
        name="email"
        value={state.email}
        onChange={handleOnChange}
      />

      {errors.generic_error != "" ? <div className="form-error"> {errors.generic_error} </div> : <div/>}

      {/* <a style={{margin: "15px auto"}} href="#">Forgot your password?</a> */}
      <div>
        <button onClick={handleResetPassword} style={{display: "flex"}}>
          <div>
            Reset Password
          </div>
          <Loader isLoading={isLoading}/>
          </button>
      </div>
    </form>
    </div>
      
  </div>

  // <div style={{color: "black"}}>
  //     Hello
  // </div>

);
};


export default ResetPassword;
